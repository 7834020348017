import React from "react";
import { StructuredDataProductCategory } from "./structured-data-product-category";
import { StructuredDataHome } from "./structured-data-home";
import { StructuredDataProduct } from "./structured-data-product";

const dataTypes = {
    "paragraph--product_list": StructuredDataProductCategory,
    "node--landing_page": StructuredDataHome,
    "shopify_product--shopify_product": StructuredDataProduct,
};

export interface StructuredDataProps {
    content,
    page?
}

export const StructuredData = ({ content, page }: StructuredDataProps) => {

    const Component = dataTypes[content?.type];

    if (!content || !Component) {
        return null;
    }

    return <Component content={content} page={page} />;
};
