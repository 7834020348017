import { ComponentPropsWithRef, ElementType, Ref, forwardRef } from 'react';

import '@/lib/utils/forwardRef';

import { TypePillProps } from './types';

/**
 * Pill
 */
const PillBase = <T extends ElementType = 'a'>(
  {
    children,
    as,
    className = '',
    ...props
  }: TypePillProps<T> & Omit<ComponentPropsWithRef<T>, keyof TypePillProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'a';

  return (
    <HTMLTag
      className={`text-button text-secondary-800 hover:text-primary-500 focus:text-primary-500 select-none px-4 py-3 rounded-full border border-secondary-800 hover:border-primary-500 focus:border-primary-500 ${className}`}
      ref={ref}
      {...props}
    >
      {children}
    </HTMLTag>
  );
};

export const Pill = forwardRef(PillBase);
