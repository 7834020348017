import { NodeProps } from "components/node";
import { Paragraph } from "components/paragraph";
import { Container } from '@/atoms';
import { StructuredData } from "../structured-data/structured-data";

export function LandingPage({ content, showPopUp }: NodeProps) {
    const isHomePage = content.node.title == "Home" ? true : false;
    const productList = content.paragraphs.find((item) => item.type == "paragraph--product_list");

    return (
        <>
            {content.node.field_show_header ?
                <Container
                    wrapper
                    classNameWrapper="pt-header"
                    backgroundColor="bg-primary-100"
                >
                    <h1 className="text-primary-600 text-headline text-headline--h2 ">
                        {content.node.title}
                    </h1>
                </Container>
                : undefined
            }

            {productList && !isHomePage ? <StructuredData page={content.node} content={{ type: "paragraph--product_list", view: content.views.products.filter((product: any) => product.tags?.some((obj: any) => obj.name == productList.field_tag.name)) }} /> : null}
            {isHomePage && <StructuredData content={{ type: "node--landing_page", metatag: content.node.metatag, paragraph: content.paragraphs.find(elem => elem.type == "paragraph--banner_slider") }} />}

            {content.paragraphs.map(((item, index) => {
                if (item.type == "paragraph--popup") {
                    if (showPopUp) {
                        return (
                            <Paragraph key={index} paragraph={item} />
                        )
                    }
                } else if (item.type == 'paragraph--article_block') {
                    return (
                        <Paragraph key={index} paragraph={item} view={content.views.articles} />
                    )
                } else if (item.type == 'paragraph--product_list') {
                    return (
                        <>
                            <Paragraph key={index} paragraph={item} view={content.views.products} />
                        </>)
                }
                else {
                    return (
                        <Paragraph key={index} paragraph={item} />
                    )
                }
            }))}
        </>)
}
