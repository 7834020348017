import { FunctionComponent, useEffect, useState } from 'react';
import Link from 'next/link';
import { oneLine } from 'common-tags';

import { Facebook, Twitter, WhatsApp } from '@/icons';
import { Pill, UnderlineTag } from '@/atoms';
import { Prose, SocialMedia } from '@/molecules';

import { TypeBlogArticleProps } from './types';

export const BlogArticle: FunctionComponent<TypeBlogArticleProps> = ({
  className = '',
  title,
  date,
  content,
  category,
  tags,
  timeToRead,
  id
}: TypeBlogArticleProps) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(document.URL);
  }, []);

  return (
    <div
      className={oneLine`
      flex flex-col gap-6 md:gap-12 
      ${className}
    `}
      id={id}
    >
      <UnderlineTag position="left" theme="light">
        {category}
      </UnderlineTag>

      <h1 className="text-headline text-headline--h2">{title}</h1>

      <div className="flex flex-col gap-2 md:flex-row text-body text-body--m">
        <span>{date}</span>
        {timeToRead && (
          <span className="text-text-secondary">{timeToRead}</span>
        )}
      </div>
      <SocialMedia
        title="COMPÁRTELO"
        socialMedias={[
          {
            Icon: Facebook,
            href: `https://www.facebook.com/sharer.php?u=${url}`,
          },
          {
            Icon: Twitter,
            href: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
          },
          {
            Icon: WhatsApp,
            href: `https://api.whatsapp.com/send?text=${title}%20${url}`,
          },
        ]}
        isColumn
        variant="gray"
        position="start"
      />

      <Prose html={content} className="text-[large] md:text-base md:leading-[28px]" />

      <hr className="text-secondary-200" />

      <SocialMedia
        title="COMPÁRTELO"
        socialMedias={[
          {
            Icon: Facebook,
            href: `https://www.facebook.com/sharer.php?u=${url}`,
          },
          {
            Icon: Twitter,
            href: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
          },
          {
            Icon: WhatsApp,
            href: `https://api.whatsapp.com/send?text=${title}%20${url}`,
          },
        ]}
        isColumn
        variant="gray"
        position="start"
      />

      {tags && (
        <>
          <span className="flex flex-col items-start text-subtitle text-subtitle--s">
            Otros temas que te pueden interesar
          </span>

          <div className="overflow-x-auto -mx-6 md:mx-0">
            <div className="flex justify-start gap-2 min-w-max px-6 md:px-0">
              {tags?.map((tag, index) => (
                <Link
                  href={tag.href}
                  prefetch={false}
                  passHref
                  key={index}
                  legacyBehavior
                >
                  <Pill>{tag.label}</Pill>
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
    </div >
  );
};

BlogArticle.displayName = 'BlogArticle';
