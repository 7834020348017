
import Head from "next/head";
import { jsonLdScriptProps } from "react-schemaorg";
import { Product } from "schema-dts";
import { cleanHtmlTags } from '../../lib/utils/utils';
import React from "react";
import { StructuredDataProps } from './structured-data'

export const StructuredDataProduct = ({ content }: StructuredDataProps) => {

    function cleanText(text) {
        return cleanHtmlTags(text).replace(/\s+/g, " ").trim();
    }

    return (
        <Head>
            <script
                {...jsonLdScriptProps<Product>({
                    "@context": "https://schema.org",
                    "@type": "Product",
                    name: content.title,
                    image: content.image.uri.url,
                    description: content.body_html ? cleanText(content.body_html.processed) : null,
                    brand: {
                        "@type": "Brand",
                        "name": "Navidul"
                    },
                    offers: {
                        "@type": "Offer",
                        "url": process.env.NEXT_PUBLIC_BASE_URL + content.path.alias,
                        "priceCurrency": "EUR",
                        "price": content.variants[0].price,
                        "itemCondition": "https://schema.org/NewCondition",
                        "availability": content.variants[0].inventory_quantity > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
                    },

                })}
            />
        </Head>
    );

}
