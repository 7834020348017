import { LineTable } from '@/molecules';
import { Table } from './Table';

export type TypeTable = typeof Table & {
  Line: typeof LineTable;
};

(Table as TypeTable).Line = LineTable;

LineTable.displayName = 'Table.Line';

export type { TypeTableProps } from './types';
export type { TypeLineTableProps } from '@/molecules/line-table';

export default Table as TypeTable;
