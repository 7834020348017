import { IconType, defaultProps } from './types';

/**
 * Icono WhatsApp
 */
export const WhatsApp = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="m3 21 1.362-5.064A9.366 9.366 0 0 1 6.891 3.91a9.405 9.405 0 0 1 12.282.794 9.373 9.373 0 0 1 .954 12.25 9.408 9.408 0 0 1-12.012 2.68L3 21Zm5.362-3.26.317.187a7.602 7.602 0 0 0 9.196-1.117 7.575 7.575 0 0 0 1.27-9.161 7.59 7.59 0 0 0-3.666-3.236A7.605 7.605 0 0 0 6.527 6.79a7.572 7.572 0 0 0-1.57 4.627 7.485 7.485 0 0 0 1.11 3.929l.199.327-.762 2.826 2.858-.76Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.04 12.718a1.567 1.567 0 0 0-1.34-.305c-.348.144-.574.69-.799.963a.332.332 0 0 1-.429.097 6.073 6.073 0 0 1-3.035-2.597.364.364 0 0 1 .049-.503c.25-.248.435-.556.536-.894a1.946 1.946 0 0 0-.247-1.07 2.504 2.504 0 0 0-.777-1.173 1.074 1.074 0 0 0-1.153.177 2.367 2.367 0 0 0-.82 1.873c0 .2.025.397.074.59.125.462.316.904.569 1.31.182.313.38.615.595.906a9.137 9.137 0 0 0 2.58 2.387 7.773 7.773 0 0 0 1.608.766 3.565 3.565 0 0 0 1.871.294 2.249 2.249 0 0 0 1.695-1.258c.092-.2.12-.425.08-.642-.097-.445-.692-.707-1.056-.921Z"
      fill={color}
    />
  </svg>
);
