
import Head from "next/head";
import { jsonLdScriptProps } from "react-schemaorg";
import { ItemList } from "schema-dts";
import { getMeta } from '../../lib/utils/utils';
import React from "react";
import { StructuredDataProps } from './structured-data'

export const StructuredDataProductCategory = ({ content, page }: StructuredDataProps) => (
    <Head>
        <script
            {...jsonLdScriptProps<ItemList>({
                "@context": "https://schema.org",
                "@type": "ItemList",
                name: page.title,
                description: getMeta(page.metatag, "description"),
                itemListElement:
                    content.view.map((product, index) => (
                        {
                            "@type": "ListItem",
                            "position": index + 1,
                            "item": {
                                "@type": "Product",
                                "name": product.title,
                                "url": process.env.NEXT_PUBLIC_BASE_URL + product.path.alias,
                                "image": product.image.uri.url,
                                "offers": {
                                    "@type": "Offer",
                                    "url": process.env.NEXT_PUBLIC_BASE_URL + product.path.alias,
                                    "priceCurrency": "EUR",
                                    "price": product.variants[0].price
                                }
                            }
                        }
                    ))
            })}
        />
    </Head>
)

