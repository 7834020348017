import { FunctionComponent } from 'react';

import { TypeTableProps } from './types';

/**
 * Table permite realizar la tabla nutricional
 * de los productos.
 */
export const Table: FunctionComponent<TypeTableProps> = ({
  children,
}: TypeTableProps) => <div>{children}</div>;
