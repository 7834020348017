import { IconType, defaultProps } from './types';

/**
 * Icono Twitter
 */
export const Twitter = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M20.95 5.255c-.604.26-1.24.442-1.89.54.303-.053.751-.601.93-.823.27-.335.477-.717.608-1.127 0-.03.03-.074 0-.096a.104.104 0 0 0-.1 0 9.563 9.563 0 0 1-2.239.87.156.156 0 0 1-.157-.039 2.172 2.172 0 0 0-.195-.204 4.08 4.08 0 0 0-1.07-.692 3.759 3.759 0 0 0-1.665-.274 4.028 4.028 0 0 0-1.583.435c-.485.266-.91.627-1.253 1.062-.359.447-.618.967-.76 1.523a4.186 4.186 0 0 0-.04 1.61c0 .092 0 .105-.078.092-3.1-.457-5.644-1.558-7.723-3.922-.09-.104-.139-.104-.213 0-.904 1.376-.465 3.552.666 4.627.152.143.308.283.474.413a3.88 3.88 0 0 1-1.488-.413c-.087-.057-.134-.026-.139.078-.012.145-.012.29 0 .435a3.954 3.954 0 0 0 2.435 3.151c.158.068.323.12.492.153-.48.094-.972.109-1.457.043-.104-.021-.143.035-.104.135.639 1.741 2.026 2.272 3.044 2.568.139.022.278.022.434.056l-.026.027c-.3.548-1.513.918-2.07 1.11a7.435 7.435 0 0 1-3.174.409c-.17-.026-.209-.022-.252 0-.044.021 0 .07.048.113a10.401 10.401 0 0 0 2.783 1.262c3.818 1.053 8.114.278 10.98-2.572 2.252-2.237 3.043-5.323 3.043-8.413 0-.118.144-.187.226-.248a7.473 7.473 0 0 0 1.492-1.558c.073-.088.11-.2.104-.314 0-.065 0-.052-.082-.017Z"
      fill={color}
    />
  </svg>
);
