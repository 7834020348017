import { FunctionComponent } from 'react';

import { Text } from '@/atoms';

import { TypeIconTextProps } from './types';

/**
 * PiconText
 */
export const IconText: FunctionComponent<TypeIconTextProps> = ({
  className = '',
  children,
  text,
}: TypeIconTextProps) => (
  <div
    className={`flex md:flex-col justify-center items-center gap-2 text-primary-600 ${className}`}
  >
    <div className="rounded-full border border-solid border-primary-600 text-button p-2">
      {children}
    </div>

    {text && <Text className="text-body text-body--s text-center">{text}</Text>}
  </div>
);
IconText.displayName = 'IconText';
