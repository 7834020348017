import { NodeProps } from "components/node";
import { Container } from '@/atoms';
import Prose from '@/molecules/prose';

export function LegalPage({ content }: NodeProps) {
    return (
        <>
            <Container
                wrapper
                classNameWrapper="pt-header"
                backgroundColor="bg-primary-100"
            >
                <span className="text-primary-600 text-headline text-headline--h2 ">
                    {content.node.title}
                </span>
            </Container>

            <Container
                wrapper
                wrapperSize="max-w-[790px]"
                classNameWrapper="overflow-hidden"
            >
                <div>
                    <Prose className="" html={content.node.field_text} />
                </div>
            </Container>

        </>
    );
}