import { Container } from '@/atoms';
import { BlogArticle } from '@/templates';
import { getUrlFromMedia, readingTime } from "@/utils/utils";
import { NodeProps } from "components/node";

export function Article({ content }: NodeProps) {
    const date = content.node.changed
        ? new Date(content.node.changed).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
        : new Date().toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });

    const reading = readingTime(content.node.field_reading_time, content.node.field_text ? content.node.field_text.value : '');
    return (
        <>

            <Container
                backgroundImage={getUrlFromMedia(content.node.field_image ?? '/')}
                className="relative h-[320px] md:h-[500px]"
                overlay={content.node.field_dark_banner === true ? 0.5 : 0}
            >
                <div className="bg-white h-[calc(100px_-_16px)] md:h-[calc(180px_-_32px)] bottom-0 right-0 left-1/2 absolute" />
            </Container>

            <Container
                wrapper
                wrapperSize="max-w-[790px]"
                padding="px-2 py-4 md:py-8"
                backgroundColor="bg-white"
            >
                {content.node.field_recipe ?
                    <BlogArticle
                        category={content.node.field_category.name}
                        title={content.node.title}
                        date={date}
                        timeToRead={reading ? `${reading} min de lectura` : null}
                        content={content.node.field_text}
                        className="bg-white -mt-[100px] md:-mt-[180px] pt-6 px-4 md:pt-10 md:px-16"
                        id='article'
                    />
                    : <BlogArticle
                        category={content.node.field_category.name}
                        title={content.node.title}
                        date={date}
                        content={content.node.field_text}
                        className="bg-white -mt-[100px] md:-mt-[180px] pt-6 px-4 md:pt-10 md:px-16"
                        id='article'
                    />}
            </Container>
        </>
    )
}
