
import Head from "next/head";
import { jsonLdScriptProps } from "react-schemaorg";
import { LocalBusiness } from "schema-dts";
import { getMeta } from '../../lib/utils/utils';
import React from "react";
import { StructuredDataProps } from './structured-data'

export const StructuredDataHome = ({ content }: StructuredDataProps) => (
    <Head>
        <script
            {...jsonLdScriptProps<LocalBusiness>({
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                additionalType: "https://www.wikidata.org/wiki/Q100988948",
                name: "Navidul",
                description: getMeta(content.metatag, "description"),
                image: content.paragraph.field_slides[0].field_image_3xl.field_media_image.uri.url,
                legalName: "Navidul Especialidades Europeas S.a.",
                taxID: "A81999013",
                url: process.env.NEXT_PUBLIC_BASE_URL,
                logo: "https://admin.navidul.es/sites/default/files/2023-08/logo.webp",
                sameAs: [
                    "https://es-es.facebook.com/navidulescueladeljamon",
                    "https://www.instagram.com/navidul/",
                    "https://www.youtube.com/user/escueladeliberico",
                    "https://twitter.com/navidul?lang=es"
                ],
                parentOrganization: {
                    "@type": "Organization",
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Toledo, Spain",
                        "postalCode": "45500"
                    },
                    "telephone": "603 79 14 49",
                    "email": "dpo.external@campofriofg.com"
                }

            })}
        />
    </Head>
)

