import { IconType, defaultProps } from './types';

/** TODO: Borrar? */

/**
 * Icono Facebook.
 */
export const Facebook = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="m16.242 13.31.521-3.316H13.55V7.839c0-.907.449-1.793 1.884-1.793h1.482V3.223A17.964 17.964 0 0 0 14.305 3C11.66 3 9.934 4.59 9.934 7.466v2.528H7v3.315h2.933v8.02h3.616v-8.02h2.693Z"
      fill={color}
    />
  </svg>
);
